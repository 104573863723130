.thankyou {
    background-color: #fab18b;
    font-family: '맑은 고딕';
    font:  '맑은 고딕';

  
  }
  
  
  
  
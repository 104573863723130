.about {
    background-color: #f0f4f8;
    padding: 4rem 0;
}

    .about h3 {
        font-size: 2rem;
        color: #1a2a4f;
        margin-bottom: 1rem;
    }

.bullet-points {
    margin-bottom: 1rem;
}

.bullet-point h5 {
    font-size: 1.2rem;
    color: #1a2a4f;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.bullet-point p {
    font-size: 1rem;
    color: #444;
    line-height: 1.6;
    margin-top: 0.5rem;
}

.image-col img {
    width: 100%;
    height: auto;
}
